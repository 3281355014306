import React from 'react'

function Footer() {
    return (
        <>


            <footer className="main-footer p-0">
                <div className="auto-container">
                    {/* Footer Content */}
                    <div className="footer-content wow fadeInUp">
                        <div className="text-center">
                            <div className="footer-logo">
                                <a href="#"><img src="images/logo-2.png" alt style={{ width: '100px', height: '100%' }} /></a>
                            </div>
                            <h3 style={{ color: 'white' }}> First International Conference on Metaverse and AI in Education for a Sustainable Society</h3>
                        </div>

                        <br></br>
                        <ul className="contact-list-one">
                            <li><i className="flaticon-location" /> Valenciennes, FRANCE <strong>Address</strong></li>
                            {/* <li><i className="flaticon-alarm-clock-1" />Wednesday - Friday 9am - 6pm <strong>Timeing</strong></li> */}
                            <li><i className="flaticon-email-1" /> <a href="icmaie.conf@gmail.com">icmaie.conf@gmail.com</a> <strong>Mail to us</strong></li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer