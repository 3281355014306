import React from 'react';

function Speakers() {
    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', paddingTop: 50, paddingBottom: 50, flexDirection: 'column'}}>
  <h1>Coming soon ...</h1>
</div>

    
    );
}

export default Speakers;
