import React from 'react'

function Authors() {
    return (
        <>
            <section className="page-title" style={{ backgroundImage: 'url(images/background/94.png)' }}>

                <div className="auto-container">
                    <div className="title-outer">
                        <h1>Authors guidelines</h1>
                        <ul className="page-breadcrumb">
                            <li><a href="/">Home</a></li>
                            <li> Authors Guidelines</li>
                        </ul>
                    </div>
                </div>
            </section>


            <section className="feature-section-two">
                <div className="anim-icons">

                    <span className="icon icon-object-5" />
                </div>
                <div className="auto-container">
                    <div className="row">
                        <div className="">
                            <div className="inner-column">
                                <div className="sec-title">
                                   <br />
                                    <h2>Paper Submission </h2>
                                    <span className="divider" />
                                    <div>
                                    <p style={{textAlign: 'justify'}}>
    Authors are required to follow the Springer Formatting Guidelines and use Word format only.
    A sample paper that could be used as a template can be found here:
    <a href="/ICMAIE2025_sample_paper.pdf" download>ICMAIE2025_sample_paper</a>.
    Please note that Springer can only accept papers in Word format for the ICMAIE 2025 proceedings volume, NOT in LaTeX.
</p>

<p style={{textAlign: 'justify'}}>
    Authors should submit papers using the Easychair conference management system at: <br />
    <a href="https://easychair.org/conferences/?conf=icmaie2025">Easychair link</a>.
</p>

<p>
    Submitted papers will be subject to a peer review process. Complete papers will be required for the review process and abstracts alone will not be sufficient. The expected types of submissions include the following:
</p>

</div>
                                </div>
                            </div>
                        </div>
                        {/* Date Column */}
                       
                    </div>
                    {/* Fact Counter Two */}
                    <div>
                    <div className="fact-counter-two" >
                        <div className="row clearfix">
                            {/*Column*/}
                            <div className="counter-column col-lg-3 col-md-6 col-sm-12">
                                <div className="inner-box">
                                    <div className="count-box counted">
                                        <p className="count-text" data-speed={3000} data-stop={54}>12   <span className='fs-6'>pages</span></p>
                                        <span className="counter-title">Full paper </span>
                                    </div>
                                </div>
                            </div>
                            {/*Column*/}
                            <div className="counter-column col-lg-3 col-md-6 col-sm-12" data-wow-delay="300ms">
                                <div className="inner-box">
                                    <div className="count-box counted">
                                        <p className="count-text" data-speed={3000} data-stop={600}>06   <span className='fs-6'>pages</span></p>
                                        <span className="counter-title">Short paper</span>
                                    </div>
                                </div>
                            </div>
                            {/*Column*/}
                            <div className="counter-column col-lg-3 col-md-6 col-sm-12 wow zoomIn animated" data-wow-delay="600ms" style={{ visibility: 'visible', animationDelay: '600ms', animationName: 'zoomIn' }}>
                                <div className="inner-box">
                                    <div className="count-box counted">
                                        <p className="count-text" data-speed={3000} data-stop={18}>06   <span className='fs-6'>pages</span></p>
                                        <span className="counter-title">Workshop paper</span>
                                    </div>
                                </div>
                            </div>
                            {/*Column*/}
                            <div className="counter-column col-lg-3 col-md-6 col-sm-12 wow zoomIn animated" data-wow-delay="900ms" style={{ visibility: 'visible', animationDelay: '900ms', animationName: 'zoomIn' }}>
                                <div className="inner-box">
                                    <div className="count-box counted">
                                        <p className="count-text" data-speed={3000} data-stop={35}>04  <span className='fs-6'>pages</span></p>
                                        <span className="counter-title">Posters</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>

 


            <section className="feature-section-two">
                <div className="anim-icons">

                    <span className="icon icon-object-5" />
                </div>
                <div className="auto-container">
                    <div className="row">
                        <div className="">
                            <div className="inner-column">
                                <div className="sec-title">
                                    <span className="sub-title">Notes</span>

                                    <span className="divider" />
                                         <div>
                                <p style={{textAlign: 'justify'}}>
    Note: The conference employs a double-blind review process, hence authors’ names and affiliations should NOT be present within the manuscript. Authors should include their names only in the appropriate fields in the paper submission form on Easychair.
  </p>
  <p style={{textAlign: 'justify'}}>
    Authors’ information should be included ONLY in the camera-ready version of the manuscript and NOT in the initial version. However, during the preparation of the initial manuscript, authors should leave a number of empty lines at the beginning of the manuscript, to include authors’ information during the camera-ready manuscript submission.
  </p>
</div>


                                </div>



                            </div>
                        </div>

                    </div>
                </div>
            </section>





        </>
    )
}

export default Authors