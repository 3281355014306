import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css'; 
import 'owl.carousel/dist/assets/owl.theme.default.css'

function Travelo() {
    return (
        <>
{/* About Section Two */}
<br />
<section className="about-section-two">
  <div className="anim-icons">
    <span className="icon icon-circle-3" />
    <span className="icon icon-circle-4" />
  </div>
  <br />
  <div className="auto-container">
    <div className="row">
      {/* Content Column */}
      <div className="content-column col-lg-12 col-md-12 col-sm-12">
        <div className="inner-column">
          <div className="sec-title">
           
            <h2> Welcome to Valenciennes </h2>
            <span className="divider" />
            <p style={{textAlign: 'justify'}}>Valenciennes is a historic city in northern France, situated near the Belgian border in the Hauts-de-France region. Known for its rich industrial past, particularly in mining and textiles, Valenciennes has transformed into a vibrant cultural hub. The city boasts beautiful architecture, including the Basilica of Notre-Dame du Saint-Cordon and the Museum of Fine Arts, which houses works by Rubens and Carpeaux. Valenciennes is also a center for higher education and innovation, with institutions like the Université Polytechnique Hauts-de-France. Its blend of history, culture, and modern development makes it a unique city in the French landscape.


                </p>
          </div>
        </div>
      </div>
     
    </div>
 <br />
    {/* Video Box Outer */}
    <div className="video-box-outer">
      {/* Video Box */}
      <div className="video-box-one order-2">
        <figure className="image"><img src="images/resource/image-1.jpg" alt /></figure>
        <a href="https://www.youtube.com/watch?v=gxDULWNJiag&t=12s" className="lightbox-image play-btn wow fadeIn" data-wow-delay="500ms"><i className="icon flaticon-play-button-1" /></a>
      </div>
      {/* Info Box */}
      <div className="info-box-one">
        <div className="inner-box">

           <img src='images/icons/carte.jpg' />
          
        </div>
      </div>
    </div>
  </div>
</section>
{/* End About Section Two */}
<section className="contact-section">
    <div className="auto-container">
        <div className="sec-title text-center">
            <span className="sub-title">Visiting Valenciennes</span>
            <h2>Getting to and around Valenciennes</h2>
            <span className="divider" />
        </div>
        <div className="row">
         
            <div className="col-md-4 px-4">
                <h3 style={{ textAlign: 'center' }}>Flying to Valenciennes</h3>
               <br></br>
               <p
    style={{
        textAlign: 'justify',
        fontSize: '14px',
        lineHeight: '1.8' 
    }}
>
    Flying to Valenciennes is straightforward, with the nearest major airport being Lille-Lesquin Airport, located about 40 minutes away by car. Lille-Lesquin offers a range of domestic and international flights, making it a convenient gateway for travelers from various locations. Alternatively, Paris Charles de Gaulle Airport is another option, situated approximately two hours away by train or car, providing extensive global connections.
</p>

            </div>
            <div className="col-md-4 px-4">
                <h3 style={{ textAlign: 'center' }}>Airport Transfer</h3>
                <br></br>
                <p
                style={{textAlign: 'justify',fontSize : '14px', lineHeight: '1.8' }} >Airport transfer options from Lille-Lesquin to Valenciennes are plentiful and convenient. Travelers can choose from various transportation methods, including car rentals, taxis, and shuttle services. Additionally, there are frequent train services from Lille to Valenciennes, providing a quick and efficient way to reach the city center. For those arriving at Paris Charles de Gaulle, direct train services are available, offering a comfortable and scenic journey to Valenciennes.
                </p>
            </div>
            <div className="col-md-4 px-4">
                <h3 style={{ textAlign: 'center' }}>Getting Around</h3>
                <br></br>
                <p
                style={{textAlign: 'justify',fontSize : '14px', lineHeight: '1.8' }}  >  Getting around Valenciennes is easy and enjoyable, thanks to its efficient public transportation system and pedestrian-friendly layout. The city boasts a reliable network of buses and trams that connect key areas and attractions, ensuring smooth transit for visitors. Moreover, Valenciennes is compact and walkable, with many sites accessible on foot or by bicycle, allowing travelers to immerse themselves fully in the city's charm and vibrant atmosphere.
                </p>
            </div>
        </div>
    </div>
</section>


{/* Gallery Section */}
<section className="gallery-section style-two">
  <div className="auto-container">
  <div className="sec-title text-center">
            <span className="sub-title">Visiting Valenciennes</span>
            <h2>Attractions</h2>
            <span className="divider" />
        </div>
 
     
  </div>
</section>
{/* Gallery Section */}
<section className="gallery-section">
  <div className="row">
    {/* Gallery Block */}
    <div className="gallery-block col-lg-3 col-md-6 col-sm-12">
      <div className="image-box">
        <figure className="image"><img src="images/gallery/v1.jpg" alt /></figure>
        <div className="overlay-box">
                 <h3 style={{ textAlign: 'center' }}>Valenciennes Museum of    <br/>   Fine Arts</h3>
             </div>
      </div>
    </div>
    {/* Gallery Block */}
    <div className="gallery-block col-lg-3 col-md-6 col-sm-12">
      <div className="image-box">
        <figure className="image"><img src="images/gallery/v2.jpg" alt /></figure>
        <div className="overlay-box">
        <h3>Grand Hornu</h3>
      
        </div>
      </div>
    </div>
    {/* Gallery Block */}
    <div className="gallery-block col-lg-3 col-md-6 col-sm-12">
      <div className="image-box">
        <figure className="image"><img src="images/gallery/v3.jpg" alt /></figure>
        <div className="overlay-box">
        <h3 style={{ textAlign: 'center' }}>Château de la Motte</h3>
     
        </div>
      </div>
    </div>
    {/* Gallery Block */}
    <div className="gallery-block col-lg-3 col-md-6 col-sm-12">
      <div className="image-box">
        <figure className="image"><img src="images/gallery/v4.jpg" alt /></figure>
        <div className="overlay-box">
        <h3 style={{ textAlign: 'center' }}>Saint-Cordon Basilica</h3>
     
        </div>
      </div>
    </div>
    {/* Gallery Block */}
    <div className="gallery-block col-lg-3 col-md-6 col-sm-12">
      <div className="image-box">
        <figure className="image"><img src="images/gallery/v5.jpg" alt /></figure>
        <div className="overlay-box">
        <h3 style={{ textAlign: 'center' }}>Museum of Contemporary Art</h3>
     
        </div>
      </div>
    </div>
    {/* Gallery Block */}
    <div className="gallery-block col-lg-3 col-md-6 col-sm-12">
      <div className="image-box">
        <figure className="image"><img src="images/gallery/v6.png" alt /></figure>
        <div className="overlay-box">
        <h3 style={{ textAlign: 'center' }}>Parc de la Rhônelle </h3>
     
        </div>
      </div>
    </div>
    {/* Gallery Block */}
    <div className="gallery-block col-lg-3 col-md-6 col-sm-12">
      <div className="image-box">
        <figure className="image"><img src="images/gallery/v7.jpg" alt /></figure>
        <div className="overlay-box">
      
        <h3 style={{ textAlign: 'center' }}>Etang du Vignoble</h3>
     
        </div>
      </div>
    </div>
    {/* Gallery Block */}
    <div className="gallery-block col-lg-3 col-md-6 col-sm-12">
      <div className="image-box">
        <figure className="image"><img src="images/gallery/v8.jpg" alt /></figure>
        <div className="overlay-box">
      
        <h3 style={{ textAlign: 'center' }}>Hôtel de ville de Valenciennes</h3>
     
        </div>
      </div>
    </div>
  </div>
</section>

 
{/*End Testimonial Section */}
<section className="testimonial-section"> 
    <div className="auto-container">
        <div className="sec-title text-center"> 
            <span className="sub-title">Visit Valenciennes</span>
            <h2>Hotels in Valenciennes</h2>
            <span className="divider"></span>
        </div>

        <div className="lists-container">
            <div className="sidebar-widget categories">
                <h5 className="sidebar-title">Hotels </h5>
                <div className="widget-content">
                    <ul className="blog-categories">
                        <li><a href="https://all.accor.com/">Hôtel Mercure Valenciennes centre</a></li>
                        <li><a href="https://royalhainaut.com/fr/">Hôtel Royal Hainaut </a></li>
                        <li><a href="https://www.insitu-hotel.com/valenciennes/"> In Situ Hôtel</a></li>
                        <li><a href="/"> Hôtel Notre Dame</a></li>
                        <li><a href="https://www.lesarcadesvalenciennes.com/">les Arcades</a></li>
                        <li><a href="https://aubergedubonsfermier.com/">Hôtel Auberge du Bon Fermier</a></li>
                    </ul>
                </div>
            </div>
            <div className="sidebar-widget categories">
                <h5 className="sidebar-title">Restaurant</h5>
                <div className="widget-content">
                    <ul className="blog-categories">
                        <li> Le coyote</li>
                        <li>Auberge du bon Fermier </li>
                        <li> Le palais des indes </li>
                        <li> Le Mythos </li>
                        <li>    Via restaurante </li>
                        <li>Léon de Bruxelles </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>



        </>
    )
}

export default Travelo