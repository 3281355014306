import React from 'react'

function Register() {
    return (
        <>

            <section className="page-title" style={{ backgroundImage: 'url(images/background/94.png)' }}>

                <div className="auto-container">
                    <div className="title-outer">
                        <h1>Registration </h1>
                        <ul className="page-breadcrumb">
                            <li><a href="/">Home</a></li>
                            <li>Registration </li>
                        </ul>
                    </div>
                </div>
            </section>




            <section className="pricing-section-two " style={{ backgroundImage: 'url(images/background/9.jpg)' }}>
                <div className="auto-container" style={{ marginTop: "50px", marginBottom: "50px" }}>
                    <div className="sec-title text-center" >
                    
                   <p style={{textAlign: 'justify'}}>
                            Author registration include: access to the conference venue, 2 daily coffee breaks, 1 ticket for the banquet,
                            presentation and publication of one paper (the registrant must be a co-author of the associated paper).
                        </p>
                        <span className="divider" />
                    </div>
                    {/* <div className="anim-icons">
                        <span className="icon icon-object-17" />
                        <span className="icon icon-object-17" />
                        <span className="icon icon-object-17" />

                    </div> */}
                    <div className="row mt-5 pt-5">
                        {/* Pricing block */}
                        <div className="pricing-block-two col-lg-4 col-md-12 col-sm-12 wow fadeInUp animated" data-wow-delay="400ms" style={{ visibility: 'visible', animationDelay: '400ms', animationName: 'fadeInUp' }}>
                            <div className="inner-box">
                                <div className="title-box">

                                    <h3>Student</h3>
                                </div>
                                <div className="price-box">
                                    <div className="price"><sup>$</sup>300</div>
                                </div>

                            </div>
                        </div>
                        {/* Pricing block */}
                        <div className="pricing-block-two tagged col-lg-4 col-md-12 col-sm-12 wow fadeInUp animated" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                            <div className="inner-box">
                                <div className="title-box">

                                    <h3>No Student</h3>
                                </div>
                                <div className="price-box">
                                    <div className="price"><sup>$</sup>350</div>
                                </div>

                            </div>
                        </div>
                        {/* Pricing block */}
                        <div className="pricing-block-two col-lg-4 col-md-12 col-sm-12 wow fadeInUp animated" data-wow-delay="400ms" style={{ visibility: 'visible', animationDelay: '400ms', animationName: 'fadeInUp' }}>
                            <div className="inner-box">
                                <div className="title-box">

                                    <h3>Industry</h3>
                                </div>
                                <div className="price-box">
                                    <div className="price"><sup>$</sup>450</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>







        </>
    )
}

export default Register