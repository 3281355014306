import React from 'react'
import { useLocation } from 'react-router-dom';

function Navbar() {
    const location = useLocation();
    return (
        <>

            <header className="main-header header-style-one">
                {/* Header top */}
                <div className="header-top">
                    <div className="auto-container">
                        <div className="inner-container">
                            <div className="top-left">
                                {/*  <ul className="social-icon-one">
              <li><a href="#"><span className="fab fa-dribbble" /></a></li>
              <li><a href="#"><span className="fab fa-facebook-f" /></a></li>
              <li><a href="#"><span className="fab fa-twitter" /></a></li>
              <li><a href="#"><span className="fab fa-pinterest-p" /></a></li>
            </ul> */}
                            </div>
                            <div className="top-right">
                                <ul className="contact-list-one">
                                    <li><i className="flaticon-location" /> Valenciennes, FRANCE <strong>Address</strong></li>
                                    {/* <li><i className="flaticon-alarm-clock-1" />Wednesday - Friday 9am - 6pm <strong>Timeing</strong></li> */}
                                    <li><i className="flaticon-email-1" /> <a href="mailto:envato@gmail.com">icmaie.conf@gmail.com</a> <strong>Mail to us</strong></li>
                                </ul>
                                <button className="nav-toggler"><i className="flaticon flaticon-menu-2" /></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Header Top */}
                {/* Header Lower */}
                <div className="header-lower">
                    <div className="auto-container">
                        {/* Main box */}
                        <div className="main-box">
                            <div className="logo-box">
                                <div className="logo"><a href="/"><img src="images/logo.png" alt title style={{ width: "100px", height: "100px", padding: "0" }} /></a></div>
                            </div>
                            <div className="nav-outer">
                                {/* Main Menu */}
                                <nav className="main-menu navbar-expand-md">
                                    <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent">
                                        <ul className="navigation clearfix">
                                            <li ><a href="/">Home</a></li>
                                            <li ><a href="/commite">Committees</a>
                                            </li>
                                            <li className={location.pathname === '/speakers' ? 'current' : ''}><a href="/speakers">Speakers</a>
                                            </li>
                                            <li className={location.pathname === '/authors' ? 'current' : ''}><a href="/authors">Authors guidelines</a>

                                            </li>
                                            <li  ><a href="/register">Registration</a>

                                            </li>

                                            <li className={location.pathname === '/travel' ? 'current' : ''}><a href="/travel">Travel</a>

                                            </li>
                                            <li className={location.pathname === '/contact' ? 'current' : ''}><a href="/contact">Contact</a></li>
                                        </ul>
                                    </div>
                                </nav>
                                {/* Main Menu End*/}

                            </div>
                        </div>
                    </div>
                </div>
           
            </header>

        </>
    )
}

export default Navbar