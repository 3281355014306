import React, { useState } from 'react'

function Track() {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    return (
        <>

            <div className="col-lg-8">
                <ul className="accordion-box">

                    {/* Track 1 */}
                    <li className={`accordion block ${activeIndex === 0 ? 'active-block' : ''}`}>
                        <div
                            className={`acc-btn ${activeIndex === 0 ? 'active' : ''}`}
                            onClick={() => toggleAccordion(0)}
                            style={{ cursor: 'pointer' }}
                        >
                            Track 1: Future Education in the Metaverse
                            <span className={`icon fa ${activeIndex === 0 ? 'fa-minus' : 'fa-plus'}`} />
                        </div>
                        {activeIndex === 0 && ( // Show content if activeIndex is 0
                            <div className="acc-content current">
                                <div className="content">
                                    <p>
                                        This track focuses on, but not limited to:
                                        <br />
                                        • Pedagogies and educational approaches in the metaverse <br />
                                        • Technology enhanced science, technology, engineering & Math education (TeSTEM) <br />
                                        • Collaborative learning in the metaverse <br />
                                        • Assessment in the metaverse <br />
                                        • Recommender system in educational metaverse environments <br />
                                        • Virtual reality, augmented reality, mixed reality, and extended reality applications in education <br />
                                        • Automatic scalarization in educational <br />
                                        • Integration of digital twins into curriculum development <br />
                                        • Industry-academia collaborations in digital twin training initiatives <br />
                                        • 3D scanning and rendering techniques for creating digital twins <br />
                                        • Simulation and modeling techniques for educational purposes
                                    </p>
                                </div>
                            </div>
                        )}
                    </li>

                    {/* Track 2 */}
                    <li className={`accordion block ${activeIndex === 1 ? 'active-block' : ''}`}>
                        <div
                            className={`acc-btn ${activeIndex === 1 ? 'active' : ''}`}
                            onClick={() => toggleAccordion(1)}
                            style={{ cursor: 'pointer' }}
                        >
                            Track 2: Security, Sustainability and Accessibility in the Metaverse
                            <span className={`icon fa ${activeIndex === 1 ? 'fa-minus' : 'fa-plus'}`} />
                        </div>
                        {activeIndex === 1 && ( // Show content if activeIndex is 1
                            <div className="acc-content current">
                                <div className="content">
                                    <p>
                                        This track focuses on, but not limited to:
                                        <br />
                                        • Cybersecurity in metaverse <br />
                                        • Blockchain and smart contracts in metaverse <br />
                                        • NFT security <br />
                                        • Assistive technology for students with disabilities <br />
                                        • Equity, diversity and inclusion in metaverse <br />
                                        • Ethical metaverse <br />
                                        • Accessibility and usability of metaverse technologies <br />
                                        • Cognitive, motivational and affective aspects for personalization <br />
                                        • Measurement and evaluation in adaptive metaverse environments <br />
                                        • Design of adaptive avatars <br />
                                        • Metaverse and society 5.0 <br />
                                        • Sustainability in and of metaverse
                                    </p>
                                </div>
                            </div>
                        )}
                    </li>

                    {/* Track 3 */}
                    <li className={`accordion block ${activeIndex === 2 ? 'active-block' : ''}`}>
                        <div
                            className={`acc-btn ${activeIndex === 2 ? 'active' : ''}`}
                            onClick={() => toggleAccordion(2)}
                            style={{ cursor: 'pointer' }}
                        >
                            Track 3: Human Machine Interactions and Collaborations in Metaverse
                            <span className={`icon fa ${activeIndex === 2 ? 'fa-minus' : 'fa-plus'}`} />
                        </div>
                        {activeIndex === 2 && ( // Show content if activeIndex is 2
                            <div className="acc-content current">
                                <div className="content">
                                    <p>
                                        This track focuses on, but not limited to:
                                        <br />
                                        • Collaborative intelligence (human intelligence and artificial intelligence) in education <br />
                                        • Teaming up with the machine in education <br />
                                        • Interactions with VR, AR, MR, and XR <br />
                                        • Brain computer interaction <br />
                                        • Somatosensory interaction <br />
                                        • Big data and Learning analytics <br />
                                        • 3D AI Modeling
                                    </p>
                                </div>
                            </div>
                        )}
                    </li>

                    {/* Track 4 */}
                    <li className={`accordion block ${activeIndex === 3 ? 'active-block' : ''}`}>
                        <div
                            className={`acc-btn ${activeIndex === 3 ? 'active' : ''}`}
                            onClick={() => toggleAccordion(3)}
                            style={{ cursor: 'pointer' }}
                        >
                            Track 4: Innovations in Digital Heritage through AI and Blockchain in the Metaverse
                            <span className={`icon fa ${activeIndex === 3 ? 'fa-minus' : 'fa-plus'}`} />
                        </div>
                        {activeIndex === 3 && ( // Show content if activeIndex is 3
                            <div className="acc-content current">
                                <div className="content">
                                    <p>
                                        This track focuses on, but not limited to:
                                        <br />
                                        • AI and Machine Learning for Cultural Heritage <br />
                                        • Blockchain, NFTs, and Digital Provenance in Heritage Preservation <br />
                                        • Immersive Digital Heritage: Virtual and Augmented Reality <br />
                                        • Motion Capture for Heritage Gesture Reconstruction <br />
                                        • NeuroMetaverse: Advancements in Neuroscience, AI, and Digital Heritage <br />
                                        • Advanced Visualization Techniques for Heritage in the Metaverse <br />
                                        • Intangible heritage protection with NFT <br />
                                        • Digitizing Heritage: Scanning Technologies, AI, and the Metaverse
                                    </p>
                                </div>
                            </div>
                        )}
                    </li>
                </ul>
            </div>

            {/* End FAQ's section */}

        </>
    )
}

export default Track