import React from 'react'
import Track from '../components/Track'
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
function Home() {
    return (
        <>
            <div className="page-wrapper">
                            <div className="form-back-drop" />
             
                {/*Main Slider*/}
                <section className="banner-section">
  <div className="slide-item" style={{backgroundImage: 'url("images/background/20.jpg")', backgroundSize: 'cover', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    <div className="auto-container">
      <div className="content-box" style={{textAlign: 'center', color: 'white', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'}}>
        <h2>First International Conference on Metaverse and AI in Education for a Sustainable Society <br></br> (ICMAIE 2025)</h2>
        <div>10-12 June Valenciennes, France</div>
        <div>Université Polytechnique Hauts-de-France</div>
      </div>
    </div>
  </div>
</section>
                {/* End Main Slider*/}
                {/* About Section */}
                <section className="about-section">
                    <div className="auto-container">
                        <div className="row">
                            {/* Image Column */}
                            <div className="image-column col-lg-6 col-md-12 col-sm-12">
                                <div className="about-image-wrapper">
                                   <figure className="image-1 wow zoomIn" data-wow-delay="300ms"><img src="images/resource/vector.png" alt /></figure>
                                </div>
                            </div>
                            {/* Content Column */}
                            <div className="content-column col-lg-6 col-md-12 col-sm-12">
                                <div className="inner-column">
                                    <div className="sec-title">
                                        <h2> Call for Papers</h2>
                                        <span className="divider" />
                                    </div>

                                   <p style={{textAlign: 'justify'}}>
 
  The International Conference on Metaverse and AI in Education for a Sustainable Society aims to bring industry
  and academia together to discuss, present, and showcase the latest research and development (R&D)
  about the immersive technology advancement and novel research on the adoption and implementation of the metaverse
  and artificial intelligence in education. Additionally, the conference seeks to identify emerging research topics
  and open issues for further investigation and collaboration.
  Furthermore, the conference underscores the significance of immersive and intelligent technologies for sustainable and inclusive social good,
  which are vital for tackling the complex challenges confronting our world and fostering a more equitable
  and environmentally responsible future education. Therefore, this conference offers a valuable opportunity
  to contribute meaningfully to the development of Education 5.0 and Society 5.0.

  The conference will be hosted by the Polytechnic University of Hauts-de-France, Valenciennes, France,
  on 10-12 June 2025. It is jointly organized by the Polytechnic University of Hauts-de-France and Beijing
  Normal University, China. <br/>  
  We invite submissions of papers reporting original academic or industrial research
  in the area of immersive and intelligent technology for Education 5.0 and Society 5.0, according to the following
  tracks (but not limited to).
</p>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End About Section */}
                <section className="faq-section" style={{ backgroundImage: 'url(images/background/91.jpg)' }}>

                    <div className="auto-container">
                        <div className="sec-title light">

                            <h2>Track</h2>
                            <span className="divider" />
                        </div>
                        <div className="row">
                            <Track />
                        </div>
                    </div>
                </section>


                <section className="schedule-section-two" style={{ backgroundImage: 'url(images/background/4.jpg)' }}>
                    <div className="anim-icons full-width">
                        <span className="icon icon-circle-2" />
                    </div>
                    <div className="auto-container">
                        <div className="schedule-tabs tabs-box">
                            <div className="sec-title-outer">
                                <div className="sec-title">

                                    <h2>
                                        Important Dates</h2>
                                    <span className="divider" />
                                </div>
                                <div className="btns-box">
                                    {/*Tabs Box*/}

                                    <ul className="tab-buttons clearfix">
                                        <li className="tab-btn active-btn" data-tab="#tab-1">
                                            <span className="day">1st Day</span>
                                            <div className="date-box">
                                                <span className="date">10</span>
                                                <span className="month"><span className="colored">June</span> 2025</span>
                                            </div>
                                        </li>

                                        <li className="tab-btn" data-tab="#tab-3">
                                            <span className="day">last Day</span>
                                            <div className="date-box">
                                                <span className="date">12</span>
                                                <span className="month"><span className="colored">June</span> 2025</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                          <div  class="col-md-10 offset-md-1">
                            <div className="tabs-content">
                                {/*Tab*/}
                                <div className="tab active-tab" id="tab-1">
                                    <div className="schedule-timeline">
                                        {/* schedule Block */}
                                        <div className="schedule-block-two">
                                            <div className="inner-box">
                                            <h3><a href="https://easychair.org/conferences/?conf=icmaie2025"> Paper submission</a> </h3>
                                                <h4>15 January 2025</h4>
                                                                                         </div>
                                        </div>
                                        {/* schedule Block */}
                                        
                                        {/* schedule Block */}
                                        <div className="schedule-block-two">
                                            <div className="inner-box">
                                                 
                                                <h3> Notification of acceptance </h3>
                                                <h4>31 March 2025</h4>

                                            </div>
                                        </div>
                                        {/* schedule Block */}
                                        <div className="schedule-block-two">
                                            <div className="inner-box">
                                                
                                                <h3>Camera ready papers</h3>
                                                <h4>30 April 2025</h4>
                                            </div>
                                        </div>
                                        <div className="schedule-block-two">
                                            <div className="inner-box">
                                               
                                                <h3>Early bird Author registration</h3>
                                                <h4>30 April 2025</h4>
                                            </div>
                                        </div>
                                        <div className="schedule-block-two">
                                            <div className="inner-box">
                                                
                                                <h3>Conference dates</h3>
                                                <h4>10-12 June 2025</h4> 

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Call to Action */}
                <section className="call-to-action" style={{ backgroundImage: 'url(images/background/90.jpg)' }}>
    <div className="auto-container">
           <div className="anim-icons">
                <span className="icon icon-object-20" />
                <span className="icon icon-object-21" />
                </div> 
                <div>
                <a href="https://slejournal.springeropen.com/" target="_blank" rel="noopener noreferrer">  <span class="icon-object-25"></span> </a>
            </div>
             
        <div className="content-box wow fadeInUp">
          
            <h2>Proceedings</h2>
            <span className="text">
                All accepted papers will be included in the conference proceedings published under Springer’s Lecture Notes in Educational Technology (LNET) series
                (<a href="https://www.springer.com/series/11777" target="_blank" rel="noopener noreferrer">https://www.springer.com/series/11777</a>).
            </span>
            <span className="text">
                Extended version of selected best accepted papers will be considered for publication in Springer’s Smart Learning Environments journal (
                <a href="https://slejournal.springeropen.com/" target="_blank" rel="noopener noreferrer">https://slejournal.springeropen.com/</a>).
            </span>
        </div>
       
    </div>
</section>
   {/* Pricing Section Two*/}
                <section className="pricing-section-three">
                    <div className="anim-icons">
                        <span className="icon icon-object-11" />
                        <span className="icon icon-object-12" />
                        <span className="icon icon-object-13" />

                    </div>
                    <div className="auto-container">
                        <div className="sec-title text-center">

                            <h2> Registation </h2>

                            <p>Author registration include: access to the conference venue, 2 daily coffee breaks, 1 ticket for the banquet, presentation and publication of one paper (the registrant must be a co-author of the associated paper).</p>
                            <span className="divider" />
                        </div>
                        <div className="row">
                            {/* Pricing block */}
                            <div className="pricing-block-three col-lg-4 col-md-12 col-sm-12 wow fadeInUp" data-wow-delay="400ms">
                                <div className="inner-box">
                                    <div className="title-box">

                                        <h3>Student</h3>

                                    </div>
                                    <div className="price-box">
                                        <div className="price"><sup> €  </sup>300</div>
                                    </div>
                                    <ul className="features">
                                        <li><strong>Full Conference</strong>  </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Pricing block */}
                            <div className="pricing-block-three tagged col-lg-4 col-md-12 col-sm-12 wow fadeInUp">
                                <div className="inner-box">
                                    <div className="title-box">

                                        <h3>Non-student</h3>

                                    </div>
                                    <div className="price-box">
                                        <div className="price"><sup>€</sup>350</div>
                                    </div>
                                    <ul className="features">
                                        <li><strong>Full Conference</strong>  </li>

                                    </ul>

                                </div>
                            </div>
                            {/* Pricing block */}
                            <div className="pricing-block-three col-lg-4 col-md-12 col-sm-12 wow fadeInUp" data-wow-delay="400ms">
                                <div className="inner-box">
                                    <div className="title-box">

                                        <h3>Industry</h3>

                                    </div>
                                    <div className="price-box">
                                        <div className="price"><sup>€</sup>450</div>
                                    </div>
                                    <ul className="features">
                                        <li><strong>Full Conference</strong> </li>

                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Pricing Section */}
             {/* Subscribe Section */}
{/* <section className="subscribe-section">
  <div className="auto-container">
    <div className="row">
      <div className="title-column col-lg-5 col-md-12">
    
        <h3> Get subscribed today!</h3>
      </div>
      <div className="form-column col-lg-7 col-md-12">
  
        <div className="newsletter-form">
          <form method="post" action="blog.html">
            
             
              <button type="submit" className="theme-btn btn-style-one"><span className="btn-title">Submit Now</span></button>
           
          </form>
        </div>
      </div>
    </div>
  </div>
</section> */}
{/*End Subscribe Section */}
 

 


                {/*Clients Section*/}
                <section className="clients-section" style={{ paddingTop: "150px" }}>
                    <div className="auto-container">
                        <div className="row">
                            {/* Title Column */}
                            <div className="title-column col-xl-3 col-lg-4 col-md-12">
                                <div className="sec-title">

                                    <h2>Organizers</h2>
                                    <div className="divider" />
                                   
                                </div>
                            </div>
                            {/* Title Column */}
                            <div className="title-column col-xl-9 col-lg-8 col-md-12">
  <div className="sponsors-outer">
    <div className="row">
      {/* Première ligne : 4 images */}
      <div class="client-block col-lg-3 col-md-6 col-sm-12 wow fadeInRight">
        <figure className="image-box">
          <a href="#"><img src="images/clients/1.png" alt style={{ width: '100%', height: '150px', objectFit: 'contain' }} /></a>
        </figure>
      </div>
      <div class="client-block col-lg-3 col-md-6 col-sm-12 wow fadeInRight">
        <figure className="image-box">
          <a href="#"><img src="images/clients/2.png" alt style={{ width: '100%', height: '150px', objectFit: 'contain' }} /></a>
        </figure>
      </div>
      <div class="client-block col-lg-3 col-md-6 col-sm-12 wow fadeInRight">
        <figure className="image-box">
          <a href="#"><img src="images/clients/7.png" alt style={{ width: '100%', height: '150px', objectFit: 'contain' }} /></a>
        </figure>
      </div>
      <div class="client-block col-lg-3 col-md-6 col-sm-12 wow fadeInRight">
        <figure className="image-box">
          <a href="#"><img src="images/clients/5.png" alt style={{ width: '100%', height: '150px', objectFit: 'contain' }} /></a>
        </figure>
      </div>
      
    </div>

    <div className="row">
      {/* Deuxième ligne : Ajouter un espacement avant pour centrer */}
 
      <div class="client-block col-lg-3 col-md-6 col-sm-12 wow fadeInRight">
        <figure className="image-box">
          <a href="#"><img src="images/clients/13.png" alt style={{ width: '100%', height: '150px', objectFit: 'contain' }} /></a>
        </figure>
      </div>
      <div class="client-block col-lg-3 col-md-6 col-sm-12 wow fadeInRight">
        <figure className="image-box">
          <a href="#"><img src="images/clients/l0.png" alt style={{ width: '100%', height: '150px', objectFit: 'contain' }} /></a>
        </figure>
      </div>
      <div class="client-block col-lg-3 col-md-6 col-sm-12 wow fadeInRight">
        <figure className="image-box">
          <a href="#"><img src="images/clients/3.png" alt style={{ width: '100%', height: '150px', objectFit: 'contain' }} /></a>
        </figure>
      </div>
      <div class="client-block col-lg-3 col-md-6 col-sm-12 wow fadeInRight">
        <figure className="image-box">
          <a href="#"><img src="images/clients/4.png" alt style={{ width: '100%', height: '150px', objectFit: 'contain' }} /></a>
        </figure>
      </div>
     
   
      <div class="col-lg-1"></div>
    </div>
  </div>
</div>
                        </div>
                    </div>
                </section>

            
                {/*End Clients Section*/}
                {/* Title Column */}
                {/* <section className="feature-section pt-0">
  <div className="anim-icons full-width">
    < div className='icon icon-circle-1'>
  <DotLottieReact
    src="lotties/anim.json" 
    loop
    autoplay
     
  />
  </div>
  </div>
  </section> */}
                <section className="clients-section " >
                    <div className="auto-container">
                        <div className="row">
                            <div className="title-column col-xl-3 col-lg-4 col-md-12">
                                <div className="sec-title">
                                    <span className="sub-title"></span>
                                    <h2>Sponsors</h2>
                                    <div className="divider" />
                                </div>
                            </div>
                            <div className="title-column col-xl-9 col-lg-8 col-md-12">
                                <div className="sponsors-outer">
                                    <div className="row">
                                        <div class="client-block col-lg-3 col-md-6 col-sm-12 wow fadeInRight">
                                            <figure className="image-box">
                                                <a href="#"><img src="images/clients/10.png" alt style={{ width: '100%', height: '150px', objectFit: 'contain' }} /></a>
                                            </figure>
                                        </div>
                                        <div class="client-block col-lg-3 col-md-6 col-sm-12 wow fadeInRight">
                                            <figure className="image-box">
                                                <a href="#"><img src="images/clients/11.png" alt style={{ width: '100%', height: '150px', objectFit: 'contain' }} /></a>
                                            </figure>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </section >

            </div>

        </>
    )
}

export default Home